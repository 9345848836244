<template>
	<div>
		<div class="percents">
			<div class="percents__item animationBlockFadeUp" v-for="(item, index) in content">
				<p class="percents--num" v-html="item.num" v-if="item.num"></p>
				<p class="percents--desc" v-html="item.desc" v-if="item.desc"></p>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Percents'
</style>

<script>
	export default {
		props: ['content'],
		data: () => ({
			
		}),
		components: {
		}
	}
</script>
